export const answers = {
    firstQuestion:
      "There are many things that can have an effect on price. But on average sedans are $200+. SUVs are $280+. MiniVans are $365+. Trucks are $200+",
    secondQuestion:
      "It really denepds on what vehicle needs to be wraped. Complexity & material amount differs from vehicle to vehicle. Its always better to contact us to get a quote.",
    thirdQuestion:
      "The Film is designed to be a sacrificial layer that takes all the beating & damage. It does not give you 100% guarantee that you will never get a paint damage. A big enough, sharp rock can still rip a hole in the film & damage the paint. But in most cases PPF will absorb the impact and leave your paint undamaged.",
    fourthQuestion:
      "Dyed window tint films give you 99% UV Rejection, privacy & some heat rejection. Ceramic films on other hand have all the abilities of regular dyed film but have a very high heat rejection compared to dyed films. So if your target is to keep your car cooler, ceramic film is your choice.",
  };
  